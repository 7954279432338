import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
import PayImage from '../images/solutions/pay.jpg'
import PulseImage from '../images/solutions/pulse.jpg'
import networking from '../images/services/networking.jpg'
import performance from '../images/services/performance.jpg'
import rationalizaion from '../images/services/rationalizaion.jpg'
import security from '../images/services/security.jpg'

import SmileyImage from '../images/solutions/smiley.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

const news = () => {
    return (
        <Layout pageTitle="PragICTS | eCommerce | News">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                            <h2>News</h2>
                   
                                <div className="horizonral-subtitle"><span>News</span></div>
                            </div>
            
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="solutions">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" >NEWS <span>//</span></div>
            <div className="container">
               
                <div className="custom-inner-holder  mar-bottom">
                    
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                          
                                <div class="post-author"><div class="author-content"><h5><a href="#">MediTec Warehouse | 04th May 2020</a></h5><p>Technomedics Group launches its eCommerce platform to enable its discerning Sri Lankan customer the convenience to shop online. The eCommerce platform is customized to enable customers to request quotations on quotation specific products and shop online for the rest of the products tagged for online shopping.</p><div class="team-single-social fl-wrap"><span>Website : </span><span><a href="https://meditecwarehouse.com/" target="_blank"><i class="fa fa-globe"></i></a></span></div></div></div>
                            
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                             
                                <div class="post-author"><div class="author-content"><h5><a href="#">ToolsHub| 01th December 2020</a></h5><p>Unicorn Metalics launches its eCommerce platform to enable its discerning Sri Lankan customer the convenience to shop online.</p><div class="team-single-social fl-wrap"><span>Website : </span><span><a href="https://www.toolshub.lk/" target="_blank"><i class="fa fa-globe"></i></a></span></div></div></div>
                          
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                   
                                <div class="post-author"><div class="author-content"><h5><a href="#">Biophilia Shop | 15th january 2021</a></h5><p>Biophilia launches its eCommerce platform to enable its discerning Sri Lankan customer the convenience to shop online</p><div class="team-single-social fl-wrap"><span>Website : </span><span><a href=" #" target="_blank"><i class="fa fa-globe"></i></a></span></div></div></div>
                       
                            </div>
                           
                        </div>
                    </div>

                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
                 

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default news
